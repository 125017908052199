(function() {
    if (document.querySelector('.business-map-container a.static-map, .business-map-directions-link')) {
        var profileEl = document.querySelector('.business-profile');
        profileEl.addEventListener('click', function(e){
            if (e.target.matches('.business-map-container a.static-map') || e.target.matches('.business-map-directions-link a')) {
                TNCMS.Tracking.addEvent({
                    'metric':'map',
                    'app':'business',
                    'id':profileEl.dataset.uuid
                });
            }
        });
    }
})();